import React, { Component } from 'react';
import axios from 'axios';
class App extends Component{
    constructor()
    {
       super();
       this.state={
            email:null,
            password:null,
            login:false,
            store:null
       }
    }
    login()
    {        
         
        axios.post('http://127.0.0.1:5000/api/login',this.state).then(response => {
                // setLoading(false);        
                console.log(response.data);
                if (response.data.description === 'ok'){
                    // setSuccess('Called Success, Return PlayerID = [' + response.data.player_id + ']')        
                    localStorage.setItem('login', JSON.stringify
                    ({
                        login:true,
                        token:response.data.token                        
                    }))
                    this.setState({login:true})
                }
                else {
                    // setError(response.data.description); 
                }       
            }).catch(error => {      
                console.log('error catch')
                console.error(error);
            // setLoading(false);
            // setError(error.response.data.description);                
            });    

    }
    render()
    {
        return (
            <div>
                <h1>This is to test JWT</h1>
                <div>
                    <input type="text" onChange={(event) => {this.setState({ email:event.target.value })}}></input>
                    <input type="password" onChange={(event) => {this.setState({password:event.target.value})}}></input>
                    <button onClick={() => {this.login()}}>login</button>
                </div>
            </div>
        )
    }
}

export default App;
