import React, { useState, useEffect } from "react";
import SideMenu from "./SideMenu";
import Header from "./Header";
import Footer from "./Footer";

const Layout = (props) => {
  const winWidth = window.innerWidth;
  const isMdSize = window.innerWidth <= 992;
  const [isMdscreen, setIsMdScreen] = useState(window.innerWidth <= 992);
  const [menu, setMenu] = useState(winWidth <= 992 ? false : true);

  const toggleMenu = () => {
    setMenu((prev) => (prev = !prev));
  };

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth <= 992) {
        if (!isMdscreen) {
          setIsMdScreen((prev) => (prev = true));
        }
      } else {
        if (isMdscreen) {
          setIsMdScreen((prev) => (prev = false));
        }
      }
    });
    return () => {
      window.removeEventListener("resize", () => {
        if (window.innerWidth <= 992) {
          if (!isMdscreen) {
            setIsMdScreen((prev) => (prev = true));
          }
        } else {
          if (isMdscreen) {
            setIsMdScreen((prev) => (prev = false));
          }
        }
      });
    };
  }, [isMdscreen]);

  return (
    <div className="layout">
      <SideMenu menuState={menu} />
      {winWidth <= 992 && menu && (
        <div className="backdrop" onClick={toggleMenu}></div>
      )}
      <div className={`main-container${menu ? "" : " main-container--expand"}`}>
        <Header
          toggleMenu={toggleMenu}
          menuState={menu}
          isMobileView={isMdSize}
        />
        <div>{props.children}</div>
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
