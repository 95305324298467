import React, { useState, useEffect, useRef } from "react";
import MobileMenuBar from "./MobileMenuBar";
import { useDispatch, useSelector } from "react-redux";
import { fetchPlayerList } from "../../store/games/gameActions";
import { setSelectedPlayer } from "../../store/games/gameSlice";
import { ErrorAlert } from "../SweetAlerts"

import { ReactComponent as Cross } from "../../assets/icons/times-solid.svg";
import { ReactComponent as User } from "../../assets/icons/user.svg";
import { ReactComponent as Search } from "../../assets/icons/search.svg";
import { ReactComponent as Message } from "../../assets/icons/message.svg";
import { ReactComponent as Wallet } from "../../assets/icons/wallet.svg";
import { ReactComponent as ArrowDown } from "../../assets/icons/angle-down.svg";
import { ReactComponent as Tick } from "../../assets/icons/tick.svg";
import secondaryLogo from "../../assets/images/favicon32.png";
import coinIcon from "../../assets/icons/coin.png";

const Header = (props) => {
  const dispatch = useDispatch();
  const dropdownRef = useRef();
  const { playerList, playerListError, selectedPlayer } = useSelector(
    (state) => ({
      playerList: state.games.playerList,
      playerListError: state.games.playerListError,
      selectedPlayer: state.games.selectedPlayer,
    })
  );
  const [userDropdown, setUserDropdown] = useState(false);

  const toggleDropdown = () => {
    setUserDropdown((prev) => (prev = !prev));
  };

  const selectPlayerHandler = (id) => {
    const filterPlayer = playerList.find((item) => item.id === id);
    if (filterPlayer) {
      dispatch(setSelectedPlayer(filterPlayer));
    }
  };

  const handleClickOutsite = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setUserDropdown((prev) => (prev = false));
    }
  };

  // initialize header, fetch player list
  useEffect(() => {
    dispatch(fetchPlayerList({}));
  }, [dispatch]);

  // dropdown event listener
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsite);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsite);
    };
  }, []);

  return (
    <div className="main-header">
      <div>
        {!props.menuState && (
          <img
            className="header_icon"
            src={secondaryLogo}
            alt="funhouse-logo"
            height={32}
            onClick={props.toggleMenu}
          />
        )}
      </div>
      {props.menuState && (
        <Cross
          className={`header_icon${props.menuState ? " header_icon--mobile" : ""
            }`}
          width="10px"
          fill="#B1AFA8"
          onClick={props.toggleMenu}
        />
      )}

      <div className="header_button-group">
        <div className="header_balance">
          <img src={coinIcon} alt="funhouse-coin" width="18px" />
          <span>
            {Object.entries(selectedPlayer).length > 0
              ? `$${parseFloat(selectedPlayer.balance).toLocaleString("en", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })}`
              : "$0.00"}
          </span>
        </div>
        {!props.isMobileView && (
          <button className="main-button">DEPOSIT</button>
        )}
      </div>

      <div className="header_panel-group">
        {props.isMobileView && (
          <div className="icon-button header_user-icon-frame">
            <Wallet width="15px" />
          </div>
        )}

        <div
          className="header_user-dropdown"
          onClick={toggleDropdown}
          ref={dropdownRef}
        >
          <div className="header_user-icon-frame">
            <User width="12px" />
          </div>
          {!props.isMobileView && (
            <label>
              {Object.entries(selectedPlayer).length > 0
                ? selectedPlayer.external_player_id
                : "Select Player"}
            </label>
          )}
          {!props.isMobileView && <ArrowDown fill="#ffffff" width="8px" />}
          <div
            className={`custom-dropdown-list${userDropdown ? " custom-dropdown-list--show" : ""
              }`}
          >
            <div className="pl-dropdown-list-div">
              {playerList.length > 0 &&
                playerList.map((item, index) => (
                  <div className={`pl-dropdown-list${item.id === selectedPlayer.id ? "--checked" : ""}`} key={`playerlist-${index}`}>
                    <div className="pl-dropdown-checklist-div">
                      {item.id === selectedPlayer.id && <span className="pl-dropdown-checklist--checked">
                        <Tick />
                      </span>}
                      {item.id !== selectedPlayer.id && <span className="pl-dropdown-checklist"></span>}
                    </div>
                    <div
                      value={item.id}
                      onClick={() => selectPlayerHandler(item.id)}
                    >
                      <span>{item.external_player_id}</span>
                      <span className="pl-dropdown-price">
                        <img src={coinIcon} alt="funhouse-coin" width="14px" />

                        ${parseFloat(item.balance).toLocaleString("en", {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        })}
                      </span>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        {!props.isMobileView && (
          <div className="icon-button">
            <Search fill="#FFBE0B" width="15px" />
          </div>
        )}
        {!props.isMobileView && (
          <div className="icon-button">
            <Message fill="#FFBE0B" width="15px" />
          </div>
        )}
      </div>

      {/* mobile menu bar */}
      {props.isMobileView && <MobileMenuBar onClick={props.toggleMenu} />}

      <ErrorAlert state={playerListError !== ""} title={"Failed to retrieve player list"} />
    </div>
  );
};

export default React.memo(Header);
