import { createSlice } from "@reduxjs/toolkit";
import { fetchPlayerList, requestStartGame } from "./gameActions";

const initialState = {
  playerList: [],
  playerListError: "",
  selectedPlayer: {},

  selectedGame: {},
  startGameError: "",
  startGameUrl: "",
};

export const gameSlice = createSlice({
  name: "games",
  initialState,

  reducers: {
    setPlayerList(state, action) {
      state.playerList = action.payload;
    },
    setPlayerListError(state, action) {
      state.playerListError = action.payload;
    },
    setSelectedPlayer(state, action) {
      state.selectedPlayer = action.payload;
    },
    setSelectedGame(state, action) {
      state.selectedGame = action.payload;
    },
    setGameUrl(state, action) {
      state.startGameUrl = action.payload;
    },
  },

  extraReducers: (builder) => {
    // fetch player list
    builder.addCase(fetchPlayerList.fulfilled, (state, action) => {
      const [response, error] = action.payload;
      if (error) {
        state.playerListError = JSON.stringify(error);
      } else {
        if (
          response.error ||
          !response.players ||
          response.description !== "ok"
        ) {
          state.playerListError = response.description;
        } else {
          state.playerList = response.players;
        }
      }
    });

    // start game
    builder.addCase(requestStartGame.fulfilled, (state, action) => {
      const [response, error] = action.payload;
      if (error) {
        state.startGameError = error;
      } else {
        if (response.error) {
          state.startGameError = response.description;
        } else {
          let currentList = JSON.parse(
            localStorage.getItem("rec_game") || "[]"
          );
          if (currentList.length > 0) {
            const gameExist = currentList.filter(
              (item) => item.id === state.selectedGame.id
            );
            if (gameExist.length > 0) {
              currentList.forEach((item) => {
                if (item.id === state.selectedGame.id) {
                  item.last_played = new Date().toJSON();
                }
              });
            } else {
              currentList.push({
                id: state.selectedGame.id,
                last_played: new Date().toJSON(),
              });
            }
          } else {
            currentList.push({
              id: state.selectedGame.id,
              last_played: new Date().toJSON(),
            });
          }
          localStorage.setItem("rec_game", JSON.stringify(currentList));
          state.startGameUrl = response.url;
        }
      }
    });
  },
});

export const {
  setPlayerList,
  setPlayerListError,
  setSelectedPlayer,
  setSelectedGame,
  setGameUrl,
} = gameSlice.actions;

export default gameSlice.reducer;
