import React, { useState, useEffect } from "react";
import {
  BrowserRouter,
  Switch,
  // Route,
  // NavLink,
  // Redirect,
} from "react-router-dom";
import axios from "axios";

// import PublicRoute from "./Utils/PublicRoute";
import { getToken, removeUserSession, setUserSession } from "./Utils/Common";

// import loginjwt from "./JWT_API/login";

// import Getcasinogames from "./Integration_API/2.1_getcasinogames";
// import CreatePlayer from "./TransferWallet_API/3.4_createplayer";
// import BalanceTransfer from "./TransferWallet_API/3.5_BalanceTransfer";
// import GetTransferStatus from "./TransferWallet_API/3.6_getTransferStatus";
// import GetBalance from "./TransferWallet_API/3.7_getBalance";
// import StartGame from "./TransferWallet_API/3.8_startGame";
// import CloseSession from "./TransferWallet_API/3.9_closesession";

import Layout from "./components/Layout/Layout";
import { newLobbyRoutes, publicRoutes, privateRoutes } from "./routes/routes";
import Authmiddleware from "./routes/AuthMiddleware";

function App() {
  const [authLoading, setAuthLoading] = useState(true);

  useEffect(() => {
    const token = getToken();
    if (!token) {
      return;
    }

    axios
      .get(`http://localhost:4000/verifyToken?token=${token}`)
      .then((response) => {
        setUserSession(response.data.token, response.data.user);
        setAuthLoading(false);
      })
      .catch((error) => {
        removeUserSession();
        setAuthLoading(false);
      });
  }, []);

  if (authLoading && getToken()) {
    return <div className="content">Checking Authentication...</div>;
  }

  return (
    <div className="App">
      <BrowserRouter>
        {/* <div>
          <div className="header">
            <NavLink exact to="/getcasinogames">
              2.1 - Get Casino Games
            </NavLink>
            <NavLink exact to="/TerminateSession">
              2.2 - Close Session
            </NavLink>
            <br />
            <NavLink exact to="/createPlayer">
              3.4 - Create Player
            </NavLink>
            <NavLink exact to="/balanceTransfer">
              3.5 - Balance Transfer
            </NavLink>
            <NavLink exact to="/getTransferStatus">
              3.6 - Get Transfer Status
            </NavLink>
            <NavLink exact to="/getBalance">
              3.7 - Get Balance
            </NavLink>
            <NavLink exact to="/startgame">
              3.8 - Start Game
            </NavLink>
          </div>
          <div className="content">
            <Switch>
                <Route exact path="/">
                  <Redirect to="/startgame" />
                </Route>
                <PublicRoute
                  path="/getcasinogames"
                  component={Getcasinogames}
                />
                <PublicRoute path="/createplayer" component={CreatePlayer} />
                <PublicRoute
                  path="/balanceTransfer"
                  component={BalanceTransfer}
                />
                <PublicRoute
                  path="/getTransferStatus"
                  component={GetTransferStatus}
                />
                <PublicRoute path="/getBalance" component={GetBalance} />
                <PublicRoute path="/startGame" component={StartGame} />
                <PublicRoute
                  path="/TerminateSession"
                  component={CloseSession}
                />
                <PublicRoute path="/jwt" component={loginjwt} />
              </Switch>
          </div>
        </div> */}

        <Switch>
          {publicRoutes.map((route, index) => (
            <Authmiddleware key={`pu-${index}`} path={route.path} component={route.components} exact />
          ))}

          {newLobbyRoutes.map((route, index) => (
            <Authmiddleware key={`pr-${index}`} path={route.path} component={route.components} layout={Layout} exact />
          ))}

          {privateRoutes.map((route, index) => (
            <Authmiddleware key={`pr-${index}`} path={route.path} component={route.components} layout="none" exact />
          ))}
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
