import { createAsyncThunk } from "@reduxjs/toolkit";
import { getPlayerList, startGame } from "./gameApi";
import { setSelectedGame } from "./gameSlice";

export const fetchPlayerList = createAsyncThunk(
  "games/fetchPlayerList",
  async (params, thunkAPI) => {
    const response = await getPlayerList({});
    return response;
  }
);

export const requestStartGame = createAsyncThunk(
  "games/requestStartGame",
  async (params, thunkAPI) => {
    thunkAPI.dispatch(setSelectedGame(params.game))
    const response = await startGame(params.reqBody);
    return response;
  }
)
