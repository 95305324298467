import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import GameCard from "../components/Card/GameCard";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { useDispatch } from "react-redux";
import { setGameUrl } from "../store/games/gameSlice";
import { requestStartGame } from "../store/games/gameActions";
import { useSelector } from "react-redux";
import { ErrorAlert } from "../components/SweetAlerts"

import { ReactComponent as ArrowRight } from "../assets/icons/arrow-right.svg";
import { ReactComponent as ArrowLeft } from "../assets/icons/arrow-left.svg";
import { ReactComponent as Search } from "../assets/icons/search.svg";

const list = [
  {
    id: "61bc80d987fd38dd6d007c49",
    image:
      "https://assets.funhouse888.com/gameassets/61bc80d987fd38dd6d007c49/rec_325.png",
    price: 0,
    game: "Panda Shore",
    company: "Funhouse",
  },
  {
    id: "61c95d8e6517b274e5f200a6",
    image:
      "https://assets.funhouse888.com/gameassets/61c95d8e6517b274e5f200a6/rec_325.png",
    price: 0,
    game: "Baccarat Super 6",
    company: "Funhouse",
  },
  {
    id: "61f016c0f4a6c01c4a29cda1",
    image:
      "https://assets.funhouse888.com/gameassets/61f016c0f4a6c01c4a29cda1/rec_325.png",
    price: 0,
    game: "Fafafa",
    company: "Funhouse",
  },
  {
    id: "61bc813587fd38dd6d007c4a",
    image:
      "https://assets.funhouse888.com/gameassets/61bc813587fd38dd6d007c4a/rec_325.png",
    price: 0,
    game: "Hot Safari",
    company: "Funhouse",
  },
  {
    id: "622e1ce5abbd9def39f3b3ac",
    image:
      "https://assets.funhouse888.com/gameassets/622e1ce5abbd9def39f3b3ac/rec_325.png",
    price: 0,
    game: "Penguin Holiday",
    company: "Funhouse",
  },
  {
    id: "622e1cf8abbd9def39f3b3ad",
    image:
      "https://assets.funhouse888.com/gameassets/622e1cf8abbd9def39f3b3ad/rec_325.png",
    price: 0,
    game: "Great Blue Reef",
    company: "Funhouse",
  },
];

const game_modes = [
  {
    key: "c",
    value: "Normal",
  },
  {
    key: "f",
    value: "Free Credit",
  },
  {
    key: "u",
    value: "Ultra",
  },
  {
    key: "DM",
    value: "Demo Play (Ultra)",
  },
];

const SlotGamesPage = (props) => {
  const dispatch = useDispatch();
  const isMobileView = window.innerWidth <= 768;
  const [currentSlide, setCurrentSlide] = useState(0);
  const [tab, setTab] = useState("allgames");
  const [filteredGames, setFilteredGames] = useState(list);
  const [recentGames, setRecentGames] = useState([]);
  const [gameMode, setGameMode] = useState("c");
  const { selectedPlayer, startGameUrl, startGameError } = useSelector(
    (state) => ({
      selectedPlayer: state.games.selectedPlayer,
      startGameUrl: state.games.startGameUrl,
      startGameError: state.games.startGameError,
    })
  );

  const previousSlide = () => {
    setCurrentSlide((prev) => (prev -= 1));
  };

  const nextSlide = () => {
    if (currentSlide + 3 < list.length || isMobileView) {
      setCurrentSlide((prev) => (prev += 1));
    }
  };

  const updateCurrentSlide = (index) => {
    if (currentSlide !== index) {
      setCurrentSlide((prev) => (prev = index));
    }
  };

  const setGameModeHandler = (event) => {
    setGameMode((prev) => (prev = event.target.value));
  };

  const toggleTab = (currTab) => {
    setTab((prev) => (prev = currTab));
    if (currTab === "allgames") {
      setFilteredGames((prev) => (prev = list));
    } else {
      setFilteredGames((prev) => (prev = recentGames));
    }
  };

  const filterGameHandler = (event) => {
    const keyword = event.target.value;

    // return back original list if input is empty
    if (!keyword) {
      if (tab === "allgames") {
        return setFilteredGames((prev) => (prev = list));
      } else {
        return setFilteredGames((prev) => (prev = recentGames));
      }
    }

    // filter
    const games = filteredGames.filter(
      (item) => item.game.toLowerCase().indexOf(keyword.toLowerCase()) >= 0
    );
    setFilteredGames((prev) => (prev = games));
  };

  const startGameHandler = async (game) => {
    // check if player is selected
    if (Object.entries(selectedPlayer).length === 0) {
      return Swal.fire({
        icon: "error",
        title: "Please select a player before start game.",
        background: "#161B1A",
      });
    } else {
      let params = {
        game_id: game.id,
        language: "en",
        game_mode: gameMode,
      };
      if (gameMode.value === "d") {
        params.currency = "MYR";
      }
      if (gameMode.value !== "DM") {
        params.external_player_id = selectedPlayer.external_player_id;
      }

      dispatch(requestStartGame({ reqBody: params, game }));
    }
  };

  // initilize this page by getting dat from localstorage
  useEffect(() => {
    const recentGamesLocal = JSON.parse(
      localStorage.getItem("rec_game") || "[]"
    );

    if (recentGamesLocal.length > 0) {
      // check for validity
      // games that played past 30 days will be removed here
      let updated = recentGamesLocal.filter((item) => {
        const diff =
          (new Date().getTime() - new Date(item.last_played).getTime()) /
          (1000 * 3600 * 24);
        return diff <= 30;
      });

      updated.sort((a, b) => new Date(b.last_played) - new Date(a.last_played));

      localStorage.setItem("rec_game", JSON.stringify(updated));

      // filter out from original game list and display on recent play section
      const ids = updated.map((item) => item.id);
      setRecentGames(
        (prev) =>
        (prev = list.filter((item) => {
          return ids.includes(item.id);
        }))
      );
    }
  }, []);

  // listen to game url, navigate and remove
  useEffect(() => {
    if (startGameUrl !== "") {
      window.open(startGameUrl, "_blank");
      dispatch(setGameUrl(""));
    }
  }, [startGameUrl, dispatch]);

  return (
    <div className="game-page">
      <div>
        <div className="game_header">Slots</div>
        <div className="game_top-container">
          <div>
            <span>TOP PICKS</span>
            <div className="game_carousel-container">
              <div>
                <span>{list.length}</span>
              </div>
              <ArrowLeft onClick={previousSlide} fill="#ffffff" width="15px" />
              <ArrowRight onClick={nextSlide} fill="#ffffff" width="15px" />
            </div>
          </div>

          <Carousel
            centerSlidePercentage={40}
            autoFocus={false}
            showArrows={false}
            showIndicators={false}
            showStatus={false}
            showThumbs={false}
            swipeable
            selectedItem={currentSlide}
            onChange={updateCurrentSlide}
          >
            {list.map((item, index) => (
              <div
                className="game_game-card"
                key={`game-list-${index}`}
                onClick={() => {
                  startGameHandler(item);
                }}
              >
                <GameCard
                  image={item.image}
                  price={item.price}
                  game={item.game}
                  company={item.company}
                />
              </div>
            ))}
          </Carousel>
        </div>
        <div className="game_game-container">
          <div>
            <div>
              <div className="game_tab">
                <span
                  className={`${tab === "allgames" ? "highlight" : ""}`}
                  onClick={() => toggleTab("allgames")}
                >
                  All Games
                </span>
                <span
                  className={`${tab === "recentplayed" ? "highlight" : ""}`}
                  onClick={() => toggleTab("recentplayed")}
                >
                  Recently Played
                </span>
              </div>
            </div>
            <div>
              <div className="game_input-container">
                <span>Sort By : </span>
                <select className="custom-dropdown">
                  <option>Popurarity</option>
                  <option>Recently Played</option>
                </select>
              </div>
              <div className="game_input-container">
                <Search />
                <input
                  type="text"
                  placeholder="Search slots..."
                  onChange={filterGameHandler}
                />
              </div>
              <div className="game_input-container">
                <span>Mode: </span>
                <select className="custom-dropdown" onChange={setGameModeHandler}>
                  {game_modes.map((item, index) => (
                    <option key={`game-mode-${index}`} value={item.key}>
                      {item.value}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div
            className={`game_bottom-game-container${filteredGames.length > 0 ? "--show" : ""
              }`}
          >
            {filteredGames.map((item, index) => (
              <div
                className="game_game-card"
                key={`botton-game-list-${index}`}
                onClick={() => {
                  startGameHandler(item);
                }}
              >
                <GameCard
                  image={item.image}
                  price={item.price}
                  game={item.game}
                  company={item.company}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="game_pagination">{`Displaying ${filteredGames.length} of ${list.length} games`}</div>
      </div>
      <ErrorAlert state={startGameError !== ""} title={startGameError} />
    </div>
  );
};

export default SlotGamesPage;
