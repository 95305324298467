import React from "react";

import { ReactComponent as Menu } from "../../assets/icons/menu.svg";
import { ReactComponent as Search } from "../../assets/icons/search.svg";
import { ReactComponent as Message } from "../../assets/icons/message.svg";
import { ReactComponent as Support } from "../../assets/icons/support.svg";

const MobileMenuBar = (props) => {
  return (
    <div className="header-mobile">
      <div onClick={props.onClick}>
        <div className="icon-button">
          <Menu fill="#FFBE0B" width="15px" />
        </div>
        <span>Menu</span>
      </div>
      <div>
        <div className="icon-button">
          <Search fill="#FFBE0B" width="15px" />
        </div>
        <span>Search</span>
      </div>
      <div>
        <div className="icon-button">
          <Message fill="#FFBE0B" width="15px" />
        </div>
        <span>Chat</span>
      </div>
      <div>
        <div className="icon-button">
          <Support fill="#FFBE0B" width="15px" />
        </div>
        <span>Support</span>
      </div>
    </div>
  );
};

export default React.memo(MobileMenuBar);
