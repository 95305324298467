import React, { useState } from "react";

import secondaryLogo from "../../assets/images/secondary-logo.png";
import { ReactComponent as AngleUp } from "../../assets/icons/angle-up.svg";

const SideMenu = (props) => {
  const [slot, setSlot] = useState(true);

  return (
    <React.Fragment>
      <div className={`menu${props.menuState ? "" : " menu--hide"}`}>
        <img width="110" src={secondaryLogo} alt="funhouse-logo" />
        <div className="menu_accordion">
          <div
            className="menu_accordion-item"
            onClick={() => setSlot((prev) => !prev)}
          >
            Casino
            <AngleUp
              className={
                slot ? "menu_accordion-angle-down" : "menu_accordion-angle-up"
              }
              fill="#ffffff"
              width="10px"
            />
          </div>
          <div
            className={
              slot ? "menu_accordion-content--show" : "menu_accordion-content"
            }
          >
            <span>Slots</span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default React.memo(SideMenu);
