import React from "react";
import { Route, NavLink } from "react-router-dom";

const Authmiddleware = ({ component: Component, layout: Layout, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (Layout && Layout !== "none") {
          return (
            <Layout>
              <React.Fragment>
                <Component {...props} />
              </React.Fragment>
            </Layout>
          );
        } else {
          if (Layout !== "none") {
            return (
              <React.Fragment>
                <div className="header">
                  <NavLink exact to="/getcasinogames">
                    2.1 - Get Casino Games
                  </NavLink>
                  <NavLink exact to="/TerminateSession">
                    2.2 - Close Session
                  </NavLink>
                  <br />
                  <NavLink exact to="/createPlayer">
                    3.4 - Create Player
                  </NavLink>
                  <NavLink exact to="/balanceTransfer">
                    3.5 - Balance Transfer
                  </NavLink>
                  <NavLink exact to="/getTransferStatus">
                    3.6 - Get Transfer Status
                  </NavLink>
                  <NavLink exact to="/getBalance">
                    3.7 - Get Balance
                  </NavLink>
                  <NavLink exact to="/startgame">
                    3.8 - Start Game
                  </NavLink>
                  <NavLink exact to="/games">
                    NEW LOBBY
                  </NavLink>
                </div>
                <Component {...props} />
              </React.Fragment>
            );
          } else {
            return (<React.Fragment>
              <Component {...props} />
            </React.Fragment>)
          }
        }
      }}
    />
  );
};

export default Authmiddleware;
