import SlotGamesPage from "../pages/SlotGamesPage";

// old routes
import Getcasinogames from "../Integration_API/2.1_getcasinogames";
import CreatePlayer from "../TransferWallet_API/3.4_createplayer";
import BalanceTransfer from "../TransferWallet_API/3.5_BalanceTransfer";
import GetTransferStatus from "../TransferWallet_API/3.6_getTransferStatus";
import GetBalance from "../TransferWallet_API/3.7_getBalance";
import StartGame from "../TransferWallet_API/3.8_startGame";
import StartGame2 from "../TransferWallet_API/3.8.2_startGame";
import CloseSession from "../TransferWallet_API/3.9_closesession";
import loginjwt from "../JWT_API/login";
import DemoPlay from "../pages/DemoPlay";

export const publicRoutes = [
  { path: "/", components: StartGame },
  { path: "/startgame", components: StartGame },
  { path: "/getcasinogames", components: Getcasinogames },
  { path: "/createPlayer", components: CreatePlayer },
  { path: "/balanceTransfer", components: BalanceTransfer },
  { path: "/getBalance", components: GetBalance },
  { path: "/getTransferStatus", components: GetTransferStatus },
  { path: "/TerminateSession", components: CloseSession },
  { path: "/jwt", components: loginjwt },
  { path: "/startgame2", components: StartGame2 },
];

export const newLobbyRoutes = [
  { path: "/games", components: SlotGamesPage },
];

export const privateRoutes = [
  { path: "/play/:lang?/:game?/:currency?", components: DemoPlay },
];
