const validateparam = (ArrList) => {     
    let params = [];       
    for (var property in ArrList) {    
        if (property.toLowerCase() === 'hash') {continue;}
        params.push(property);
    }

     let returnErr = "";
    for (var p of params.sort()) {                 
        if (!ArrList[p])       
        { returnErr = "Field missing : [" + p + "]"; break; }        
    }    
    return returnErr;
}
export default validateparam;