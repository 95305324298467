import React, { useState } from 'react';
import axios from 'axios';
import calculateHash  from '../Utils/hashController'; 
import validateparam  from '../Utils/validateController';


function GetTransferStatus() {  
  const [loading, setLoading] = useState(false);  
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
    
  const Secure_Token = useFormInput(process.env.REACT_APP_SECURE_TOKEN);  
  const Ext_TransID = useFormInput('');  
  
  // handle button click of login form
  const HandleGetTransferStatus = () => {
    setSuccess(null);
    setError(null);
    setLoading(true);        

    const schema =
    {
        secureToken: Secure_Token.value,
        external_transaction_id: Ext_TransID.value,
        hash:''    
    };     
    let Param_Err = validateparam(schema);
     if (Param_Err){setError(Param_Err); setLoading(false);return; }    
 schema.hash = calculateHash(schema);              
    
    axios.post(process.env.REACT_APP_BACKEND_ADDRESS + '/balance/transferstatus',schema).then(response => {
        setLoading(false);   
        console.log(response.data);                 
         if (response.data.description === 'ok'){
           let Display_Text = 'Amount :' + response.data.amount + ' | ';
            Display_Text += 'transaction_id : ' + response.data.transaction_id + ' | ';
            Display_Text += 'date : ' + response.data.date + ' | ';
            Display_Text += 'balance : ' + response.data.balance;

            setSuccess(Display_Text)        
        }
        else {setError(response.data.description); }    

    }).catch(error => {         
       setLoading(false);
       console.log(error);
       setError(error.response.data.description);                
    });    
  }

  return (
    <div>     
      <div style={{display:"none"}}>
        Secure Token<br />
        <input type="text" {...Secure_Token} />
      </div>
      <div style={{ marginTop: 10 }}>
        External Transaction ID<br />
        <input type="text" {...Ext_TransID} />
      </div>   
      {error && <><small style={{ color: 'red' }}>{error}</small><br /></>}<br />
      {success && <><small style={{ color: 'green' }}>{success}</small><br /></>}<br />
      <input type="button" value={loading ? 'Loading...' : 'Get Transfer Status'} onClick={HandleGetTransferStatus} disabled={loading} /><br />
      
      

    </div>
  );
}

const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);

  const handleChange = e => {
    setValue(e.target.value);
  }
  return {
    value,
    onChange: handleChange
  }
}

export default GetTransferStatus;