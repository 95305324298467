import axios from "axios";
import calculateHash from "./hashController";
import validateparam from "./validateController";

export const post = (url, params, auth = false) => {
  const domain = process.env.REACT_APP_BACKEND_ADDRESS || "";
  const secureToken = process.env.REACT_APP_SECURE_TOKEN || "";

  // validate params
  if (Object.entries(params).length > 0) {
    const validateError = validateparam(params);
    if (validateError)
      return {
        status: "error",
        msg: validateError,
      };
  }

  const reqBody = {
    ...params,
    secureToken,  // required param
    hash: "",     // required param
  };

  // calculate hash
  reqBody.hash = calculateHash(reqBody);
  return axios.post(domain + url, reqBody);
};
