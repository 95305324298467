import React from "react";

import { ReactComponent as Heart } from "../../assets/icons/heart.svg";
import { ReactComponent as Info } from "../../assets/icons/info.svg";

const GameCard = (props) => {
  return (
    <div className="game-card">
      {/* <div className="game-card_price-tag">${ props.price }</div> */}
      <img src={props.image} alt="funhouse-slot-games" />
      <div className="game-card_label-container">
        <span>{ props.game }</span>
        <Heart fill="#ffffff" width="20px" />
      </div>
      <div className="game-card_label-container">
        <span>{ props.company }</span>
        <Info fill="#ffffff" width="20px" />
      </div>
    </div>
  );
};

export default React.memo(GameCard);
