import React from "react";
import Swal from "sweetalert2";

const ErrorAlert = props => {
  if (props.state) {
    Swal.fire({
      icon: 'error',
      title: props.title,
      background: "#161B1A",
    })
  }
  return <></>
}

export default React.memo(ErrorAlert)