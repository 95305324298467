const md5 = require('md5');
 
const calculateHash = (ArrList, SecretKey) => {         
    let params = [];       
    for (var property in ArrList) {    
        if (property.toLowerCase() === 'hash') {continue;}
        params.push(property);
    }
    //console.log(ArrList);
    
     let hashString = "";
    for (var p of params.sort()) {                
        hashString += p + "=" + ArrList[p] + "&";
    }
    hashString = hashString.substring(0, hashString.length - 1);
    var md5hash = md5(hashString + "&secret_key=" + SecretKey);
        
    return md5hash;
}
export default calculateHash;
