import React from "react";
import primaryLogo from "../../assets/images/primary-logo.png";

const Footer = (props) => {
  return (
    <div className="footer">
      <div className="footer-section-logo">
        <img src={primaryLogo} alt="funhouse-logo" width="80" />
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry.
        </p>
      </div>
      <div className="footer-section">
        <p className="footer_subtitle">PLATFORM</p>
        <hr />
        <a href="/# ">Suppport</a>
        <a href="/# ">FAQ</a>
        <a href="/# ">Partnership Program</a>
        <a href="/# ">Blog</a>
      </div>
      <div className="footer-section">
        <p className="footer_subtitle">ABOUT US</p>
        <hr />
        <a href="/# ">AML Policy</a>
        <a href="/# ">Responsible Gaming</a>
        <a href="/# ">Privacy Policy</a>
        <a href="/# ">Terms and Conditions</a>
      </div>
      <div className="footer-section">
        <p className="footer_subtitle">COMMUNITY</p>
        <hr />
        <a href="/# ">Facebook</a>
        <a href="/# ">Twitter</a>
        <a href="/# ">Instagram</a>
        <a href="/# ">Discord</a>
      </div>
      <div className="footer-section-logo--mobile">
        <img src={primaryLogo} alt="funhouse-logo" width="80" />
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry.
        </p>
      </div>
    </div>
  );
};

export default Footer;
