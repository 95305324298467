import React, { useEffect, useState } from 'react';
import axios from 'axios';
import calculateHash  from '../Utils/hashController'; 
import validateparam  from '../Utils/validateController';

function StartGame() {      
  const Game_Mode =  useFormInput('c');      
  const [Token, setToken] = useState('61bc828787fd38dd6d007c4c');
  const [SecretKey, setSecretKey] = useState('1kjahsd981723');

  // const Ext_PlayerID = useFormInput('1');    
  const Language = useFormInput('en');   

  const [extPlayerID, setextPlayerID] = useState('');
  
  const [balance, setBalance] = useState(0);

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);  
  const [ playerList, setplayerList] = useState([]); 

  
  // const [balance, setBalance] = useState(0);
  // handle button click of login form
  const HandleStartGame = (GameID) => {     
    setSuccess(null);
    setError(null);
    // setLoading(true);                     
    const schema =
    {
        secureToken: Token,        
        game_id:GameID,        
        language:Language.value,             
        game_mode:Game_Mode.value,        
        hash:''    
    };             
    if (Game_Mode.value === 'd') { schema.currency = 'MYR'; }
    if (Game_Mode.value !== 'DM') { schema.external_player_id = extPlayerID; }
    
    let Param_Err = validateparam(schema);
    if (Param_Err){
      setError(Param_Err); 
      // setLoading(false);
      return; 
    }    
    
     schema.hash = calculateHash(schema , SecretKey);                      
     axios.post(process.env.REACT_APP_BACKEND_ADDRESS + '/startGame',schema).then(response => {
        // setLoading(false);
            
        console.log(response.data);
        if (response.data.description === 'ok'){

            // setSuccess('Called Success, Return URL = [' + response.data.url + ']')                  
            window.open(response.data.url);
        }
        else {setError(response.data.description);                }                

    }).catch(error => {               
      //  setLoading(false);
       setError(error.response.data.description);                
    });    
  }
  const handleChange = (value) => {
    //  selectOptionSetter(value)    
     setextPlayerID(value)     
    //  alert(extPlayerID);
     // handle other stuff like persisting to store etc
      const schema =
        {
            secureToken: Token,
            external_player_id:value,
            hash:''    
        };     
          let Param_Err = validateparam(schema);
          if (Param_Err){console.log(Param_Err);return; }    
        schema.hash = calculateHash(schema, SecretKey);              

        axios.post(process.env.REACT_APP_BACKEND_ADDRESS + '/player/balance',schema).then(response => {      

          console.log(response.data);              
          if (response.data.description === 'ok'){
              // alert(response.data.balance); 
               setBalance(response.data.balance);
              
          }
          else { alert('fail retreiveing balance')}    

      }).catch(error => {      
        console.log(error);       
      });          
   }

   const handleChangeMerchant = (value) => {                           
        setToken(value.split('|')[0]);          
        setSecretKey(value.split('|')[1]);          
   }

   useEffect(() => {            
    const schema_playerlist =
    {
        secureToken: Token,        
        hash:''
    };            
    if (Token !== '')
    {      
      schema_playerlist.hash = calculateHash(schema_playerlist, SecretKey);                      
      axios.post(process.env.REACT_APP_BACKEND_ADDRESS + '/player/list',schema_playerlist).then(response => {                     
          if (response.data.description === 'ok'){                      
              setplayerList(response.data.players);                         
          }                
          else {
              alert(response.data.description);       
          }
      }).catch(error => {                     
        console.log(error.response.data.description);  
        setplayerList([]);             
        alert(error.response.data.description)
      });
    }    
   }, [Token, SecretKey]);
  
  //  const GetMerchant_Token = () =>  { return Merchant.split('|')[0]; }
  //  const GetMerchant_SecretKey = () => {  return Merchant.split('|')[1]; }
  return (
   <div className="col-md-12 slots">  
    
         <div className="card" id="">
            <div className="card-body">
            <div>Choose Merchant  &nbsp;&nbsp;                            
                  <select    id="ddl_merchant"  onChange={e => handleChangeMerchant(e.target.value)} >                  
                  <option value="61bc828787fd38dd6d007c4c|1kjahsd981723">FH - Malaysia</option>
                  <option value="61efa06794e3f4a9a910b4c0|aKJovX73eDuf10GRLoQkHKiv1umTGZ">FH - Thailand </option>                                    
                </select>                                          
            </div>
          </div>
            <div className="card-body">
                <div>Select Player &nbsp;&nbsp;                                                         
                  <select  id="ddl_playerid"  onChange={e => handleChange(e.target.value)}>
                    <option key='' value=''>Select Player</option>
                      {playerList && playerList
                      .sort((a,b) => a.external_player_id
                      .localeCompare(b.external_player_id))
                      .map((player) => <option key={player.external_player_id} value={player.external_player_id}>{player.external_player_id} - {player.balance}</option>)}                                     
                  </select>
         </div>
            </div>
            <div className="card-body" style={{display:"none"}}>
                <div>Balance : {balance}</div>    
                {/* <div id='div_balance'>{balance}</div> */}
            </div>
                <div className="card-body">
                <div>Game Mode &nbsp;&nbsp;
                  <select     {...Game_Mode}  >
                  <option value="d">Demo </option>
                  <option value="c">Normal </option>                  
                  <option value="f">Free Credit </option>                                                                        
                  <option value="u">Ultra </option>    
                  <option value="DM">Demo Play (Ultra)</option>    
                </select>
         </div>
            </div>

                <div className="card-body">
                <div>Language &nbsp;&nbsp;
                  <select     {...Language}  >
                  <option value="en">English </option>
                  <option value="th">Thai </option>                  
                  <option value="cn">Chinese </option>                                                                        
                </select>
         </div>
            </div>
            <hr></hr>
            <div className="card-body">
              <button class="btn btn-primary" onClick={() => HandleStartGame('62b30e7799559445a10127f2')} >Bounty Temple</button>               
            </div>      
            <hr></hr>   
            <div className="card-body">
              <button class="btn btn-primary" onClick={() => HandleStartGame('622e1ce5abbd9def39f3b3ac')} >Penguin Holiday</button>               
            </div>              
            <div className="card-body">
              <button class="btn btn-primary" onClick={() => HandleStartGame('622e1cf8abbd9def39f3b3ad')} >Great Blue Reef</button>               
            </div>              
                         
                  
           
            
         
         </div>
        {error && <><small style={{ color: 'red' }}>{error}</small><br /></>}<br />
       {success && <><small style={{ color: 'green' }}>{success}</small><br /></>}<br />
   </div>
)
}

const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue); 
  const handleChanges = e => {    
    setValue(e.target.value);
  }
  return {
    value,
    onChange: handleChanges
  }
}




export default StartGame;