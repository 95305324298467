import { post } from "../../Utils/axiosHelper";

export const getPlayerList = async (params) => {
  try {
    const result = await post("/player/list", params);
    return [result.data, null]
  } catch (error) {
    return [null, error];
  }
};

export const startGame = async (params) => {
  try {
    const result = await post("/startGame", params);
    return [result.data, null]
  } catch (error) {
    return [null, error];
  }
}
