import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import crypto from "crypto";

const DemoPlay = (props) => {
  const [error, setError] = useState(false)
  const { lang, game, currency } = useParams();

  const hashParams = (params) => {
    let hashString = ""
    for (let [index, key] of Object.keys(params).sort().entries()) {
      hashString += key + "=" + params[key]
      if (index !== Object.entries(params).length - 1) {
        hashString += "&"
      } else {
        hashString += "&secret_key=1kjahsd981723"
      }
    }
    const hash = crypto.createHash("md5").update(hashString).digest("hex")
    return hash;
  }

  useEffect(() => {
    if (!lang || !game) {
      setError(prev => prev = true)
    } else {
      let params = {
        game_id: game,
        game_mode: "DM",
        language: lang,
        currency: currency ? currency.toUpperCase() : "USD",
        secureToken: "61bc828787fd38dd6d007c4c"
      }
      const hash = hashParams(params)
      params["hash"] = hash
      axios.post(process.env.REACT_APP_BACKEND_ADDRESS + '/startGame', params).then(response => {
        if (response.data.description === 'ok') {
          window.open(response.data.url, "_self");
        } else {
          setError(prev => prev = true);
        }
      }).catch(error => {
        setError(prev => prev = true);
      });
    }
  }, [lang, game, currency])

  return (
    <div style={{
      "display": "flex",
      "justifyContent": "center",
      "height": "100%",
      "backgroundColor": "#F6F8F8"
    }}>
      {
        error &&
        <span style={{
          "backgroundColor": "#fff",
          "borderRadius": "10px",
          "padding": "40px",
          "height": "100px",
          "marginTop": "100px",
        }}>Demo is not available for this game</span>
      }
    </div>
  );
};

export default DemoPlay;
