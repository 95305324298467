import React, { useState } from 'react';
import axios from 'axios';
import calculateHash  from '../Utils/hashController'; 
import validateparam  from '../Utils/validateController';



function Balancetransfer() {   
     
  const [loading, setLoading] = useState(false);  
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
     
  const [Token, setToken] = useState('61bc828787fd38dd6d007c4c');
  const [SecretKey, setSecretKey] = useState('1kjahsd981723');
  const Ext_PlayerID = useFormInput('');  

  var length = 15;
  var myNumber = (Math.floor(Math.pow(10, length-1) + Math.random() * 9 * Math.pow(10, length-1)));
  const Ext_TransactionID = useFormInput(myNumber);  
  const Amount = useFormInput('');      

  // handle button click of login form
  const HandleTransfer = () => {
    setSuccess(null);
    setError(null);
    setLoading(true);        

     const schema =
    {
        secureToken: Token,
        external_player_id: Ext_PlayerID.value,
        external_transaction_id:Ext_TransactionID.value,
        amount:Amount.value,
        hash:''    
    };     
    let Param_Err = validateparam(schema);
    if (Param_Err){setError(Param_Err); setLoading(false);return; }    
    
    schema.hash = calculateHash(schema, SecretKey);              

      axios.post(process.env.REACT_APP_BACKEND_ADDRESS + '/balance/transfer',schema).then(response => {
        setLoading(false);        
        console.log(response.data);
        if (response.data.description === 'ok'){
            let SuccessText = 'Transaction ID = [' + response.data.transaction_id + '] ';
            SuccessText += 'Balance = [' + response.data.balance+ ']';

            setSuccess('Called Success ' + SuccessText )        
        }
        else {setError(response.data.description);                }                

    }).catch(error => {      
       setLoading(false);
       console.log(error);
       setError(error.response.data.description);                
    });    
}
const handleChangeMerchant = (value) => {                           
  setToken(value.split('|')[0]);          
  setSecretKey(value.split('|')[1]);          
}

  return (
    <div>     
      <div style={{ marginTop: 10 }} >Choose Merchant  <br />
                  <select    id="ddl_merchant"  onChange={e => handleChangeMerchant(e.target.value)} >                  
                  <option value="61bc828787fd38dd6d007c4c|1kjahsd981723">FH - Malaysia</option>
                  <option value="61efa06794e3f4a9a910b4c0|aKJovX73eDuf10GRLoQkHKiv1umTGZ">FH - Thailand </option>                                    
                </select>                                          
            </div>
      <div style={{ marginTop: 10 }}>
        PlayerID<br />
        <input type="text" {...Ext_PlayerID}  />
      </div>
      <div style={{ marginTop: 10 }}>
        Amount<br />
        <input type="text" {...Amount} />
      </div>
      <div style={{ marginTop: 10 }}>
        External Transaaction ID<br />
        <input type="text" {...Ext_TransactionID} />
      </div>
      {error && <><small style={{ color: 'red' }}>{error}</small><br /></>}<br />
      {success && <><small style={{ color: 'green' }}>{success}</small><br /></>}<br />
      <input type="button" value={loading ? 'Loading...' : 'Transfer'} onClick={HandleTransfer} disabled={loading} /><br />
      
      

    </div>
  );
}

const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);

  const handleChange = e => {
    setValue(e.target.value);
  }
  return {
    value,
    onChange: handleChange
  }
}

export default Balancetransfer;