import React, { useEffect, useState } from 'react';
import axios from 'axios';
import calculateHash  from '../Utils/hashController'; 
import validateparam  from '../Utils/validateController';


function GetcasinoGames() {  
  const  tblstyle = 
  {    
      padding: "5px",
      border: "1px solid black"         
  }

  const [loading, setLoading] = useState(false);    

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [gameresult, setGameResult] = useState([]);
  
  const [Token, setToken] = useState('61bc828787fd38dd6d007c4c');
  const [SecretKey, setSecretKey] = useState('1kjahsd981723');

  // handle button click of login form
  const HandleGetCasinoGames = () => {
    setSuccess(null);
    setError(null);
    setLoading(true);        

    const schema =
    {
        secureToken: Token,        
        hash:''    
    };     
    let Param_Err = validateparam(schema);
     if (Param_Err){setError(Param_Err); setLoading(false);return; }    
 schema.hash = calculateHash(schema, SecretKey);              
    
    axios.post(process.env.REACT_APP_BACKEND_ADDRESS + '/games',schema).then(response => {
        setLoading(false);  
        console.log(response.data);              
         if (response.data.description === 'ok'){                         
            setGameResult(response.data.games)                                                                   
        }
        else {setError(response.data.description); }    

    }).catch(error => {      
       setLoading(false);
       setError(error.response.data.description);                
    });    
  }
  const handleChangeMerchant = (value) => {                           
    setToken(value.split('|')[0]);          
    setSecretKey(value.split('|')[1]);              
  }
  useEffect(() => {                
    document.getElementById("btngetgame").click();
  }, [Token, SecretKey]);
  return (
    <div>      
    
      <div className="card-body">
            <div>Choose Merchant  &nbsp;&nbsp;                            
                  <select    id="ddl_merchant"  onChange={e => handleChangeMerchant(e.target.value)} >                  
                  <option value="61bc828787fd38dd6d007c4c|1kjahsd981723">FH - Malaysia</option>
                  <option value="61efa06794e3f4a9a910b4c0|aKJovX73eDuf10GRLoQkHKiv1umTGZ">FH - Thailand </option>                                    
                </select>                                          
            </div>
          </div>
          <img width="325" height="234" alt="" src="https://cmsupload-dev.s3.amazonaws.com/Avater_Ellipse.png"></img>

        <table style={tblstyle}>              
          <tr>
            <td style={tblstyle}>Game ID</td>
            <td style={tblstyle}>Game Name</td>
            <td style={tblstyle}>Img (325 * 234)</td>
            <td style={tblstyle}>Img (188 * 83)</td>
            <td style={tblstyle}>Img (160 * 115)</td>
            <td style={tblstyle}>Img (200 * 200)</td>
            <td style={tblstyle}>Img (138 * 138)</td>
            <td style={tblstyle}>Img (Cover)</td>
            </tr>
            <tr>
            <td>Company Logo</td>
            <td style={tblstyle}>-</td>
            <td style={tblstyle}><img width="325" height="234" alt="" src={ process.env.REACT_APP_ASSETS_URL + "funhouse/rec_325.png"}></img></td>
            <td style={tblstyle}><img width="188" height="83" alt="" src={ process.env.REACT_APP_ASSETS_URL + "funhouse/rec_188.png"}></img></td>
            <td style={tblstyle}><img width="160" height="115" alt="" src={ process.env.REACT_APP_ASSETS_URL + "funhouse/rec_160.png"}></img></td>
            <td style={tblstyle}><img width="200" height="200" alt="" src={ process.env.REACT_APP_ASSETS_URL + "funhouse/square_200.png"}></img></td>
            <td style={tblstyle}><img width="138" height="138" alt="" src={ process.env.REACT_APP_ASSETS_URL + "funhouse/square_138.png"}></img></td>
            </tr>
          {
             gameresult.map(game =>
              <tr key={game.id}>
                <td style={tblstyle}><b>{game.game_name}</b></td>
                <td style={tblstyle}><b>{game.id}</b></td>
                <td style={tblstyle}><img width="325" height="234" alt="" src={ process.env.REACT_APP_ASSETS_URL + "gameassets/" + game.id + "/rec_325.png"}></img></td>
                <td style={tblstyle}><img width="188" height="83" alt="" src={ process.env.REACT_APP_ASSETS_URL + "gameassets/" + game.id + "/rec_188.png"}></img></td>
                <td style={tblstyle}><img width="160" height="115" alt="" src={ process.env.REACT_APP_ASSETS_URL + "gameassets/" + game.id + "/rec_160.png"}></img></td>
                <td style={tblstyle}><img width="200" height="200" alt="" src={ process.env.REACT_APP_ASSETS_URL + "gameassets/" + game.id + "/square_200.png"}></img></td>
                <td style={tblstyle}><img width="138" height="138" alt="" src={ process.env.REACT_APP_ASSETS_URL + "gameassets/" + game.id + "/square_138.png"}></img></td>
                <td style={tblstyle}><img width="138" height="138" alt="" src={ process.env.REACT_APP_ASSETS_URL + "gameassets/" + game.id + "/cover.png"}></img></td>
              </tr>              
          ) 
          }
        </table>

        
      {error && <><small style={{ color: 'red' }}>{error}</small><br /></>}<br />
      {success && <><small style={{ color: 'green' }}>{success}</small><br /></>}<br />
      <input type="button" id="btngetgame" value={loading ? 'Loading...' : 'Get Game'} onClick={HandleGetCasinoGames} disabled={loading} /><br />      
    </div>
  );
}

export default GetcasinoGames;