import React, { useEffect, useState } from 'react';
import axios from 'axios';
import calculateHash  from '../Utils/hashController'; 
import validateparam  from '../Utils/validateController';

function StartGame() {      
  const Game_Mode =  useFormInput('c');      
  const [Token, setToken] = useState('61bc828787fd38dd6d007c4c');
  const [SecretKey, setSecretKey] = useState('1kjahsd981723');

  // const Ext_PlayerID = useFormInput('1');    
  const Language = useFormInput('en');   

  const [extPlayerID, setextPlayerID] = useState('');
  
  const [balance, setBalance] = useState(0);

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);  
  const [ playerList, setplayerList] = useState([]); 

  
  // const [balance, setBalance] = useState(0);
  // handle button click of login form
  const HandleStartGame = (GameID) => {     
    setSuccess(null);
    setError(null);
    // setLoading(true);                     
    const schema =
    {
        secureToken: Token,        
        game_id:GameID,        
        language:Language.value,             
        game_mode:Game_Mode.value,        
        hash:''    
    };             
    if (Game_Mode.value === 'd') { schema.currency = 'MYR'; }
    if (Game_Mode.value !== 'DM') { schema.external_player_id = extPlayerID; }
    
    let Param_Err = validateparam(schema);
    if (Param_Err){
      setError(Param_Err); 
      // setLoading(false);
      return; 
    }    
    
     schema.hash = calculateHash(schema , SecretKey);                      
     axios.post(process.env.REACT_APP_BACKEND_ADDRESS + '/startGame',schema).then(response => {
        // setLoading(false);
            
        console.log(response.data);
        if (response.data.description === 'ok'){

            // setSuccess('Called Success, Return URL = [' + response.data.url + ']')                  
            window.open(response.data.url);
        }
        else {setError(response.data.description);                }                

    }).catch(error => {               
      //  setLoading(false);
       setError(error.response.data.description);                
    });    
  }
  const handleChange = (value) => {
    //  selectOptionSetter(value)    
     setextPlayerID(value)     
    //  alert(extPlayerID);
     // handle other stuff like persisting to store etc
      const schema =
        {
            secureToken: Token,
            external_player_id:value,
            hash:''    
        };     
          let Param_Err = validateparam(schema);
          if (Param_Err){console.log(Param_Err);return; }    
        schema.hash = calculateHash(schema, SecretKey);              

        axios.post(process.env.REACT_APP_BACKEND_ADDRESS + '/player/balance',schema).then(response => {      

          console.log(response.data);              
          if (response.data.description === 'ok'){
              // alert(response.data.balance); 
               setBalance(response.data.balance);
              
          }
          else { alert('fail retreiveing balance')}    

      }).catch(error => {      
        console.log(error);       
      });          
   }

   const handleChangeMerchant = (value) => {                           
        setToken(value.split('|')[0]);          
        setSecretKey(value.split('|')[1]);          
   }

   useEffect(() => {            
    const schema_playerlist =
    {
        secureToken: Token,        
        hash:''
    };            
    if (Token !== '')
    {      
      schema_playerlist.hash = calculateHash(schema_playerlist, SecretKey);                      
      axios.post(process.env.REACT_APP_BACKEND_ADDRESS + '/player/list',schema_playerlist).then(response => {                     
          if (response.data.description === 'ok'){                      
              setplayerList(response.data.players);                         
          }                
          else {
              alert(response.data.description);       
          }
      }).catch(error => {                     
        console.log(error.response.data.description);  
        setplayerList([]);             
        alert(error.response.data.description)
      });
    }    
   }, [Token, SecretKey]);
  
  //  const GetMerchant_Token = () =>  { return Merchant.split('|')[0]; }
  //  const GetMerchant_SecretKey = () => {  return Merchant.split('|')[1]; }
  return (
   <div className="col-md-12 slots">  
    
         <div className="card" id="">
            <div className="card-body" style={{padding:"0.5rem"}}>
            <div>Choose Merchant  &nbsp;&nbsp;                            
                  <select    id="ddl_merchant"  onChange={e => handleChangeMerchant(e.target.value)} >                  
                  <option value="61bc828787fd38dd6d007c4c|1kjahsd981723">FH - Malaysia</option>
                  <option value="61efa06794e3f4a9a910b4c0|aKJovX73eDuf10GRLoQkHKiv1umTGZ">FH - Thailand </option>                                    
                </select>                                          
            </div>
          </div>
            <div className="card-body" style={{padding:"0.5rem"}}>
                <div>Select Player &nbsp;&nbsp;                                                         
                  <select  id="ddl_playerid"  onChange={e => handleChange(e.target.value)}>
                    <option key='' value=''>Select Player</option>
                      {playerList && playerList
                      .sort((a,b) => a.external_player_id
                      .localeCompare(b.external_player_id))
                      .map((player) => <option key={player.external_player_id} value={player.external_player_id}>{player.external_player_id} - {player.balance}</option>)}                                     
                  </select>
         </div>
            </div>
            <div className="card-body" style={{display:"none"}}>
                <div>Balance : {balance}</div>    
                {/* <div id='div_balance'>{balance}</div> */}
            </div>
                <div className="card-body" style={{padding:"0.5rem"}}>
                <div>Game Mode &nbsp;&nbsp;
                  <select     {...Game_Mode}  >
                  <option value="d">Demo </option>
                  <option value="c">Normal </option>                  
                  <option value="f">Free Credit </option>                                                                        
                  <option value="u">Ultra </option>    
                  <option value="DM">Demo Play (Ultra)</option>    
                </select>
         </div>
            </div>

                <div className="card-body">
                <div>Language &nbsp;&nbsp;
                  <select     {...Language}  >
                  <option value="en">English </option>
                  <option value="th">Thai </option>                  
                  <option value="cn">Chinese </option>                                                                        
                </select>
         </div>
            </div>
            <hr></hr>
            <div>
              <table>
                <tr>
                  <td className="card-body"><a href="/#" onClick={() => HandleStartGame('61bc80d987fd38dd6d007c49')} >Panda Riches</a></td> 
                  <td className="card-body"> <a href="/#" onClick={() => HandleStartGame('6331aadb8ffef96ac173c5e4')} >Bonsai Riches</a> </td>           
                  <td className="card-body"> <a href="/#" onClick={() => HandleStartGame('647702378b1fd64645a662b8')} >Tree of Fortune</a> </td>           
            </tr>
            <tr>
              <td className="card-body"><a href="/#" onClick={() => HandleStartGame('61f016c0f4a6c01c4a29cda1')} >fafafa</a></td>
              <td className="card-body"><a href="/#" onClick={() => HandleStartGame('6347c95d80fa39b828916234')} >Juicy Fruity</a></td>
              <td className="card-body"> <a href="/#" onClick={() => HandleStartGame('64993074ccb55fddb2e143ef')} >Monopoly</a> </td>           
            </tr>
            <tr>
               <td className="card-body"><a href="/#" onClick={() => HandleStartGame('622e1ce5abbd9def39f3b3ac')} >Penguin Holiday</a></td>
               <td className="card-body"><a href="/#" onClick={() => HandleStartGame('636ae0e2163e20088e072243')} >Fomo Riches</a></td>
           </tr>           
            <tr>
               <td className="card-body"><a href="/#" onClick={() => HandleStartGame('622e1cf8abbd9def39f3b3ad')} >Great Blue Reef</a></td>
               <td className="card-body"><a href="/#" onClick={() => HandleStartGame('62b30e7799559445a10127f2')} >Bounty Temple</a></td>
           </tr>
           <tr>
                <td className="card-body"><a href="/#" onClick={() => HandleStartGame('628b53249ebf423a9268bca5')} >Truck Bonanza</a></td>
                <td className='card-body'><a href="/#" onClick={() => HandleStartGame('6396c5c443e71d49d35c3556')} >Genie, Lamp Of Wishes</a></td>
            </tr>
            <tr>
               <td className="card-body"><a href="/#" onClick={() => HandleStartGame('62a43ce5004987eecd8552b7')} >King Savannah</a></td>
               <td className='card-body'><a href="/#" onClick={() => HandleStartGame('63b7c1b66108ae408fc465fa')} >Cai Shen Fa</a></td>
               </tr>
            <tr>
               <td className="card-body"><a href="/#" onClick={() => HandleStartGame('62f0b1972f9c2729a1cd1de1')} >Tropical Fiesta</a></td>
               <td className='card-body'><a href="/#" onClick={() => HandleStartGame('63d7a321b02b51590e04a8aa')} >Three Dragons </a></td>
               </tr>
            <tr>
               <td className="card-body"><a href="/#" onClick={() => HandleStartGame('631ef2b0595c95b96996cd50')} >El Pinata</a></td>
               <td className='card-body'><a href="/#" onClick={() => HandleStartGame('64097d8b26a0869a40b7b77e')} >Race to Riches</a></td>
            </tr>
          
            </table>
          
            <div className="card-body">
               
            </div>
            </div>
            <hr></hr>
            <div className="card-body">

               <a href="/#" onClick={() => HandleStartGame('61c95d8e6517b274e5f200a6')} >Baccarat S6</a> <br></br>
               <a href="/#" onClick={() => HandleStartGame('63443ccc80fa39b82891621c')} >BaccaratS6_VIP0</a> <br></br>
               <a href="/#" onClick={() => HandleStartGame('632d4b718ffef96ac173c5c2')} >BaccaratS6_VIP1</a> <br></br>
               <a href="/#" onClick={() => HandleStartGame('632d4b7e8ffef96ac173c5c3')} >BaccaratS6_VIP2</a> <br></br>
               <a href="/#" onClick={() => HandleStartGame('632d4b8c8ffef96ac173c5c4')} >BaccaratS6_VIP3</a>
            </div>
            <div className="card-body">
              <a href="/#" onClick={() => HandleStartGame('62424da53e42534e2f693833')} >Baccarat Easy Six</a>
            </div>
            <hr></hr>
             <div className="card-body">
               <a href="/#" onClick={() => HandleStartGame('61bc813587fd38dd6d007c4a')} >Hot Safari</a>
            </div>
            <div className="card-body">
              <a href="/#" onClick={() => HandleStartGame('62393064afbc9ac6b1f0d05b')}>Panther Moonlight</a>
            </div>
            
         
         </div>
        {error && <><small style={{ color: 'red' }}>{error}</small><br /></>}<br />
       {success && <><small style={{ color: 'green' }}>{success}</small><br /></>}<br />
   </div>
)
}

const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue); 
  const handleChanges = e => {    
    setValue(e.target.value);
  }
  return {
    value,
    onChange: handleChanges
  }
}




export default StartGame;