import React, { useState } from 'react';
import axios from 'axios';
import calculateHash  from '../Utils/hashController'; 
import validateparam  from '../Utils/validateController';

function CloseSession() {  
  const [loading, setLoading] = useState(false);  
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
    
  const Secure_Token = useFormInput(process.env.REACT_APP_SECURE_TOKEN);    
  const Ext_PlayerID = useFormInput('');  
  const GameID = useFormInput('');  

  // handle button click of login form
  const HandleCloseSession = () => {
    setSuccess(null);
    setError(null);
    setLoading(true);        

    const schema =
    {
        secureToken: Secure_Token.value,
        external_player_id: Ext_PlayerID.value,        
        hash:''    
    };    
     
    if (GameID.value){ schema.game_id = GameID.value; }
    let Param_Err = validateparam(schema);
    if (Param_Err){setError(Param_Err); setLoading(false);return; }    

    schema.hash = calculateHash(schema);              
    
    axios.post(process.env.REACT_APP_BACKEND_ADDRESS + '/game/terminateSession',schema).then(response => {
        setLoading(false);    
        console.log(response.data);            
         if (response.data.description === 'ok'){
            setSuccess('Called Success')        
        }
        else {setError(response.data.description); }    

    }).catch(error => {      
       setLoading(false);
       console.log(error);
       setError(error.response.data.description);                
    });    
  }

  return (
    <div>    
      <div style={{display:"none"}}>
        Secure Token<br />
        <input type="text" {...Secure_Token} autoComplete="new-password" />
      </div>
      <div style={{ marginTop: 10 }}>
        External Player ID<br />
        <input type="text" {...Ext_PlayerID} autoComplete="new-password" />
      </div>    
       <div style={{ marginTop: 10 }}>
        Game ID<br />
        <input type="text" {...GameID} placeholder="Optional" />
      </div>    
      {error && <><small style={{ color: 'red' }}>{error}</small><br /></>}<br />
      {success && <><small style={{ color: 'green' }}>{success}</small><br /></>}<br />
      <input type="button" value={loading ? 'Loading...' : 'Terminate'} onClick={HandleCloseSession} disabled={loading} /><br />
      
      

    </div>
  );
}

const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);

  const handleChange = e => {
    setValue(e.target.value);
  }
  return {
    value,
    onChange: handleChange
  }
}

export default CloseSession;